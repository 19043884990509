import sanitizeHtml from 'sanitize-html'
import _ from 'lodash'
import isElectron from 'is-electron';

const origin = isElectron() ? '#' : window.location.origin //+ window.location.pathname

//used
export function sanitizeAndHighlight(text, linkPrefix = '') {
  const sanitized = sanitizeDBDocText(text.replace(/&?n?b?s?p;/gi, ' ').replace(/^[^<]*>/, '').replace(/<\\?p>/gi, '').replace(/<p[^>]+>/gi, '').replace(/<br\/?>/gi, ''), linkPrefix, true)
  return sanitized.html
}

const months = ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Noi', 'Dec']
  , monthsLong = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']

//used
export function date2ShortDiff(date) {
  const now = new Date()
    // , diff = (now - date) / 1000
    // , days = diff / (24 * 60 * 60)
    , sameYear = now.getFullYear() === date.getFullYear()
    // , sameMonth = sameYear && now.getMonth() === date.getMonth()
    // , sameDay = sameMonth && now.getDate() === date.getDate()
    // , dateStr = sameDay ? 'azi' : (sameMonth && now.getDate() - date.getDate() === 1) ? 'ieri' : `${date.getDate()} ${months[date.getMonth()]} ${sameYear ? '' : date.getFullYear()}`
    , dateStr = `${date.getDate()} ${months[date.getMonth()]}${sameYear ? '' : ' ' + date.getFullYear()}`
    , timeStr = `${date.getHours()}:${pad(date.getMinutes(), 2)}`
  // console.log(date, now, days, sameDay)
  return { dateStr, timeStr }
}

//used
export function date2DDMMMYYYString(date) {
  return `${pad(date.getDate(), 2)}/${months[date.getMonth()]}/${date.getFullYear()}`
}
//used
export function date2DDMMMMMYYYString(date) {
  return `${pad(date.getDate(), 2)} ${monthsLong[date.getMonth()]} ${date.getFullYear()}`
}

function filterStylesAndAlign(attribs) {

  const uselessStyles = ['font-family', 'font-size', 'mso-', 'margin']
    , reds = ['red', 'maroon', '#800000', '#dd143c', '#ff0000']
    , greens = ['green']
    , styles = attribs.style ? attribs.style.split(';').filter(Boolean) : []

  let style = ""
    , newAttribs = Object.assign({}, attribs)
  if (styles.length) {
    let remapped = styles.map(s => {
      const trimmed = s.trim()
      if (trimmed) {
        const parts = trimmed.split(':'),
          name = parts[0].trim().toLowerCase(),
          val = parts[1].trim()
        if (!val)
          return ''
        if (name === 'color')
          // console.log('color: ' + val)
          if (name === 'color' && -1 !== reds.indexOf(val.toLowerCase())) {
            //red
            newAttribs.class = [attribs.class, 'lex-taiat'].join(' ')
            // console.log("RED", val)
            return '';
          }
        if (name === 'color' && -1 !== greens.indexOf(val.toLowerCase())) {
          //red
          newAttribs.class = [attribs.class, 'lex-adaugat'].join(' ')
          // console.log("GREEN")
          return '';
        }
        if (name === 'color' && val === 'black') {
          return '';
        }
        if (uselessStyles.filter(s => parts[0].indexOf(s) !== -1).length > 0) {
          return '';
        }
        return name + ':' + val
      }
      return ''
    }).filter(Boolean)
    style = remapped.join(';')
  }
  if (attribs.color && -1 !== reds.indexOf(attribs.color.toLowerCase())) {
    newAttribs.class = [attribs.class, 'lex-taiat'].join(' ')
    delete newAttribs.color
    // console.log("REDCOLOR", newAttribs)
  }
  if (attribs.color && -1 !== greens.indexOf(attribs.color.toLowerCase())) {
    newAttribs.class = [attribs.class, 'lex-adaugat'].join(' ')
    delete newAttribs.color
  }
  if (attribs.align) {
    style = [style, `text-align:${attribs.align}`].join(';')
  }
  if (attribs.valign) {
    style = [style, `vertical-align:${attribs.valign}`].join(';')
  }
  if (style)
    newAttribs.style = style
  else
    delete newAttribs.style
  return newAttribs
}

export function sanitizeDBDocText(text, linkPrefix = '', noWrap = false) {
  if (!text || text.length === 0) {
    return { html: "", lexids: [] };
  }
  // filter out all empty tags
  const allowedTags = sanitizeHtml.defaults.allowedTags.concat(['img', 'hh', 'font', 'span', 'sup'])//.filter(tag => tag !== 'pre')
    , preSanitizedHtml = text
      .replace(/<div> *<\/div>/gi, '')
      .replace(/<span> *<\/span>/gi, '')
      .replace(/(\S)\s+$/, "$1")
      .replace(/<hh>/gi, '<span class="lex-highlight">')
      .replace(/<\/hh>/gi, '</span>')
      .replace(/^\s+(\S)/, "$1")
      .replace(/\r\n/g, "\n")//.replace(/&nbsp;+/g, ' ')

  let lexids = []

  const justFilterStylesAndAlign = function (tagName, attribs) {
    return {
      tagName,
      attribs: filterStylesAndAlign(attribs)
    }
  }

  const sanitizedHtml = sanitizeHtml(preSanitizedHtml, {
    xmlMode: false,
    allowedTags,
    allowedAttributes: {
      img: ['src', 'alt', 'border', 'hspace', 'style', 'vspace'],
      div: ['data-*'],
      a: ['name', 'id', 'href', 'text', 'target'],
      p: ['align', 'style'],
      font: ['class'],
      // table: ['style'],
      td: ['style', 'valign'],
      br: [],
      b: [],
      span: ['id', 'style', 'class']
    },
    transformTags: {
      'p': justFilterStylesAndAlign,
      'span': justFilterStylesAndAlign,
      'font': justFilterStylesAndAlign,
      'td': justFilterStylesAndAlign,
      'a': function (tagName, attribs) {
        let newTag
        if (/^doc:/i.test(attribs['href'])) {
          const { lexid, anchor } = decodeLexLink(attribs.href)
          lexids.push(lexid)
          newTag = {
            tagName: 'a',
            attribs: { href: `${origin}${linkPrefix}/viewlexlink/${lexid}${anchor ? '#' + anchor : ''}`, target: "_self" }
          }
        } else {
          if (attribs.name) {
            newTag = {
              tagName: 'a',
              attribs: { id: attribs.name, name: attribs.name }
            }
          } else {
            newTag = {
              tagName: 'a',
              attribs: filterStylesAndAlign(attribs)
            }
          }
        }
        return newTag
      }
    },
    // nonTextTags: ['img'],
    exclusiveFilter: frame => ['divx', 'a', 'spanx', 'px', 'fontx'].indexOf(frame.tag) !== -1 && !frame.text.trim(),
    allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'doc', 'data', 'file']
  })
    , postSanitizedText = /^ *<pre>/.test(sanitizedHtml) ? sanitizedHtml : sanitizedHtml
      .replace(/ {2,}/g, ' ')
      // .replace(/> +</g, '><')
      .replace(/<p> *<\/p>/g, '')
      .replace(/<div> *<\/div>/gi, '')
      .replace(/<span> *<\/span>/gi, '')
      .replace(/<font> *<\/font>/gi, '')
      .replace(/<p> *<\/p>/g, '')
      .replace(/<div> *<\/div>/gi, '')
      .replace(/<span> *<\/span>/gi, '')
      .replace(/<font> *<\/font>/gi, '')
      .replace(/<p> *<\/p>/g, '')
      .replace(/<div> *<\/div>/gi, '')
      .replace(/<span> *<\/span>/gi, '')
      .replace(/<font> *<\/font>/gi, '')
      .replace(/> {2,}/gi, '> ')
      .replace(/> +</gi, '> <')
      // .replace(/ *<\//gi, '</')
      .replace(/(\S)\s+$/, "$1")
      .replace(/^\s+(\S)/, "$1")

  lexids = _.uniq(lexids)
  if (/<\/[\S]+>/.test(postSanitizedText)) {
    return { html: noWrap ? postSanitizedText : `<div>${postSanitizedText}</div>`, lexids }
  } else {
    return { html: noWrap ? postSanitizedText : `<pre>${postSanitizedText}</pre>`, lexids }
  }

  // return postSanitizedText
}

function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function decodeLexLink(fullLexlink) {

  const matches = fullLexlink.match(/doc:([^#]+)(?:#(.*))?/i)
    , lexlink = matches[1]
    , anchor = matches[2]

  let
    // anoff
    // , an
    an
    , nract
    , codemitent
    , codtip
    , parts

  // FORMATU Plii = 1? aa nnnnn ee / tt
  const dupa2000 = /^1(\d{2})(\d{5})(\d{2})\/(\d{1,2})/
    , inainteDe2000 = /^(\d{2})(\d{5})(\d{2})\/(\d{1,2})/
  if (dupa2000.test(lexlink)) {
    parts = lexlink.match(dupa2000)
  } else if (inainteDe2000.test(lexlink)) {
    parts = lexlink.match(inainteDe2000)
  }

  an = parseInt(parts[1], 10)
  nract = parseInt(parts[2], 10)
  codemitent = parseInt(parts[3], 10)
  codtip = parseInt(parts[4], 10)

  const lexid = pad(nract, 4) + pad(codtip, 2) + pad(codemitent, 2) + '.' + pad(an, 2);

  return { lexid, anchor }
}

export const highlightSearchTextInDocument = (docSearchText, documentData) => {

  if (!docSearchText || !documentData || docSearchText.length <= 1) return null
  const { html, title } = documentData
  const sanitizedDocSearchText = docSearchText
    .replace(/\./g, '\\.')
    .replace(/\[/g, '')
    .replace(/\]/g, '')
    .replace(/{/g, '')
    .replace(/}/g, '')
    .replace(/nr\\.\s*(\d+)/g, "nr\\.\\s*$1")
  const rex1 = new RegExp(`(${sanitizedDocSearchText})`, 'gi')
  let rex2 = null
  let combinedRex = rex1
  const matchInsideTagRex = new RegExp(`(<[^>]*?)(${sanitizedDocSearchText})(?=[^>]*?>)`, 'g')

  if (/^\d{4}$/.test(docSearchText)) {
    const [first, ...last3] = docSearchText.split('')
    combinedRex = new RegExp(`(${sanitizedDocSearchText}|${first}\\.${last3.join('')})`, 'gi')
    rex2 = new RegExp(`(${first}\\.${last3.join('')})`, 'gi')
  }
  if (combinedRex.test(html) || combinedRex.test(title)) {
    let replacedDoc = html
    let replacedTitle = title
    let matchedInsideTag = false
    // console.log(replacedDoc)
    if (matchInsideTagRex.test(html)) {
      replacedDoc = html.replace(matchInsideTagRex, '$1~=L-E-X=~')
      replacedTitle = title.replace(matchInsideTagRex, '$1~=L-E-X=~')
      matchedInsideTag = true
      // console.log(replacedDoc)
    }

    replacedDoc = replacedDoc
      .replace(combinedRex, `<span class="lex-highlight">$1</span>`)
    replacedTitle = replacedTitle
      .replace(combinedRex, `<span class="lex-highlight">$1</span>`)

    // updatedSearchIndex = useSavedTopAndSearchIndex ? savedTopAndSearchIndex.searchIndex : 0
    const found = (replacedDoc.match(rex1) || []).length + (rex2 ? (replacedDoc.match(rex2) || []).length : 0)

    if (matchedInsideTag) {
      replacedDoc = replacedDoc.replace(/~=L-E-X=~/g, docSearchText)
      replacedTitle = replacedTitle.replace(/~=L-E-X=~/g, docSearchText)
    }
    // console.log(replacedDoc)
    return {
      html: replacedDoc,
      title: replacedTitle,
      found,
    }
  }
}
