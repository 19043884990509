import React, { useContext, useEffect, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as dayjs from 'dayjs';
import 'dayjs/locale/ro';
import { UserContext } from './context'
import DocumentBody from './fragments/DocumentBody'
import DocHistorySidePanel from './DocHistorySidePanel'
import DocRelationsSidePanel from './DocRelationsSidePanel'
import DocTrackingSidePanel from './DocTrackingSidePanel'

import { date2DDMMMMMYYYString, highlightSearchTextInDocument } from './lib/textutils'
import DocDescription from './fragments/DocDescription'
import BetterTopNav from './BetterTopNav'
import NavSearchBox from './fragments/NavSearchBox'
import { searchText2searchParams } from './lib/searchutils'
import isElectron from 'is-electron'
import { SearchContext } from './contexts/SearchContext'
import { isMobile } from './customhooks/useMobile'

import './styles/from-editor/lists.scss';
import './styles/from-editor/document-styles.scss';

const useLatestRevisionOnLinks = true

function getHTMLOfSelection() {
  let range;
  if (document.selection && document.selection.createRange) {
    range = document.selection.createRange();
    return range.htmlText;
  }
  else if (window.getSelection) {
    let selection = window.getSelection();
    if (selection.rangeCount > 0) {
      range = selection.getRangeAt(0);
      let clonedSelection = range.cloneContents();
      let div = document.createElement('div');
      div.appendChild(clonedSelection);
      return div.innerHTML;
    }
    else {
      return '';
    }
  }
  else {
    return '';
  }
}

const printSelection = () => {
  const selectionHTML = getHTMLOfSelection();
  const printWindow = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
  printWindow.document.write(`<style>.lex-highlight {
    font-weight: bold;
    background-color: #ffed77;
    color: #333;
    display: inline-block;
  }</style>`,
    selectionHTML);
  printWindow.document.close();
  printWindow.focus();
  printWindow.print();
  printWindow.close();
}

const printDocument = () => {
  const documentHTML = document.getElementById('document-body').cloneNode(true);
  if (documentHTML) {
    const printWindow = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    printWindow.document.write(
      // `<style>.lex-highlight {
      //   font-weight: bold;
      //   background-color: #ffed77;
      //   color: #333;
      //   display: inline-block;
      // }</style>`,
      `<div id='root'></div>`);
    const mainPrintElement = printWindow.document.getElementById('root');

    if (mainPrintElement) {
      mainPrintElement.appendChild(documentHTML);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    } else {
      console.error('cannot add content to print element');
      printWindow.document.close();
    }
    printWindow.close();
  } else {
    console.error('cannot find tag with id = document-body')
  }
}

const LoadingSpinner = ({ loading }) => loading ? <i className="fa fa-refresh fa-spin fa-fw"></i> : null

const ShowHideReds = ({ doc, onToggle, showReds }) => doc && doc.revdata.rev_type === "Modificare" ? (
  <div className="doc-tools-button py-0" onClick={onToggle}>
    <i className={`fa fa-eye${showReds ? '-slash' : ''}`}></i> {`${showReds ? 'ascunde' : 'arata'}`} modificarile
  </div>
) : null

const DocDOCXDownloadButton = ({ doc, onExport, loading }) => doc ? <button className="btn btn-sm btn-link ml-2 py-0" onClick={onExport}><i className="fa fa-file-word-o"></i> Export Word <LoadingSpinner loading={loading} /></button> : null
const DocPDFDownloadButton = ({ doc, onExport, loading }) => doc ? <button className="btn btn-sm btn-link ml-2 py-0" onClick={onExport}><i className="fa fa-file-pdf-o"></i> Export PDF <LoadingSpinner loading={loading} /></button> : null
const PrintButton = ({ doc, selectionOnly = false }) => doc
  ? <button
    className="btn btn-sm btn-link ml-2 py-0"
    onClick={() => selectionOnly ? printSelection() : printDocument()}
  >
    <i className="fa fa-print"></i> Printeaza {selectionOnly ? 'selectie' : ''}
  </button>
  : null

const DocSearchBox = ({ doc, docSearchText = '', searchIndex, setSearchIndex, onSearch }) => {

  const [searchText, setSearchText] = useState(docSearchText)

  useEffect(() => setSearchText(docSearchText), [docSearchText])

  if (!doc) return null

  const totalSearchFinds = doc.found

  return (
    <div className="form form-inline pb-2 pt-2">
      <div className="input-group">
        <div className="input-group-prepend">
          <button className="input-group-btn btn btn-outline-secondary btn-sm fs-10" type="button"
            onClick={() => onSearch(searchText)}>
            <i className="fa fa-search"></i>
          </button>
        </div>
        <input type="text" className={`form-control form-control-sm`}
          placeholder="Cauta in text"
          // value={searchText}
          onChange={e => setSearchText(e.target.value)}
          onKeyUp={(e) => { if (e.code === 'Enter') onSearch(searchText) }} />
      </div>
      {totalSearchFinds ? (
        <div className="input-group ml-1" style={{ height: 24 }}>
          <div className="input-group-prepend">
            <button className="input-group-btn btn btn-outline-secondary btn-sm fs-10" type="button" onClick={() => {
              setSearchIndex(prev => prev > 0 ? prev - 1 : totalSearchFinds - 1)
            }}>
              <i className="fa fa-chevron-left"></i>
            </button>
          </div>
          <div className="form-control text-muted small px-1 py-1" style={{ height: 24, lineHeight: '16px', width: 'auto', fontSize: 12 }}>
            {searchIndex + 1}/{totalSearchFinds}
          </div>
          {/* <input type="text" readonly className={`form-control form-control-sm`} value= /> */}
          <div className="input-group-append">
            <button className="input-group-btn btn btn-outline-secondary btn-sm fs-10" type="button" onClick={() => {
              setSearchIndex(prev => prev < totalSearchFinds - 1 ? prev + 1 : 0)
            }}>
              <i className="fa fa-chevron-right"></i>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

const _getSelectionHtml = () => {
  const selection = document.getSelection()
  if (selection.rangeCount !== 0) {
    const selectionRange = selection.getRangeAt(0)
    const isInDocBody = window.$(selectionRange.commonAncestorContainer).closest('#document-body').length !== 0
    if (!selectionRange.collapsed && isInDocBody) {
      const selectedText = selectionRange.cloneContents()
      const div = document.createElement("div")
      div.appendChild(selectedText)
      return div.outerHTML
    }
  }
  return null
}

const _titleForDoc = (doc, isFragment) => {
  const actionString = doc.revdata.rev_type === 'Publicare' ? doc.revdata.rev_number === 1 ? 'Publicare' : 'Republicare' : 'Modificare'
    , formattedRevDate = date2DDMMMMMYYYString(new Date(doc.revdata.rev_date))
    , title = `${doc.newtype} ${doc.issuers[0].issuer_nr}|${doc.issue_year} - ${actionString} ${formattedRevDate}${isFragment ? ' (fragment)' : ''}`
  return title
}

const _scrollToSearchIndex = (searchIndex) => {
  const elements = document.getElementsByClassName('lex-highlight')
  const elementAtSearchIndex = elements[searchIndex]
  if (elementAtSearchIndex) {
    elementAtSearchIndex.scrollIntoView({ block: "end", inline: "nearest" })
  }
}

const BetterViewer = ({ match: { params: { lexid, docid, docSearchText: docSearchTextFromParams = '' } } }) => {

  const mobile = isMobile();

  const { loggedInUser, api } = useContext(UserContext)
  const { searchText } = useContext(SearchContext)
  const history = useHistory()

  const [documentData, setDocumentData] = useState()
  const [documentMeta, setDocumentMeta] = useState()
  const [loading, setLoading] = useState(false)

  const [showReds, setShowReds] = useState(true)
  const [searchIndex, setSearchIndex] = useState(0)
  const [headerMinimize, setHeaderMinimize] = useState(false)

  const [docSearchText, setDocSearchText] = useState(decodeURIComponent(docSearchTextFromParams))

  const [loadError, setLoadError] = useState(null)

  useEffect(() => {
    setDocSearchText(decodeURIComponent(docSearchTextFromParams))
  }, [docSearchTextFromParams])

  // DOCUMENT STUFF
  useEffect(() => {
    const promise = docid
      ? api.loadDocumentById(docid, useLatestRevisionOnLinks)
      : api.loadDocumentByLexId(lexid)
    setLoading(true)
    setLoadError(null)
    promise.then(({ html, title, ...meta }) => {
      setLoading(false)
      setDocumentData({ html, title })
      setDocumentMeta(meta)
    }).catch(error => {
      setLoadError(error)
    })
  }, [lexid, docid, api])

  const presentedDocumentData = useMemo(() => {
    const highlighted = highlightSearchTextInDocument(docSearchText, documentData)
    if (!highlighted) {
      return documentData
    }
    return highlighted
  }, [documentData, docSearchText])

  const doc = useMemo(() => {
    if (documentMeta && presentedDocumentData) {
      return { ...documentMeta, ...presentedDocumentData }
    }
    return null
  }, [documentMeta, presentedDocumentData])
  // /EO DOCUMENT STUFF

  const toggleTracked = async () => {
    try {
      await (documentMeta.tracked ? api.stopTrackingDocument : api.startTrackingDocument)(documentMeta.revdata.chain_id)
      setDocumentMeta(prev => ({ ...prev, tracked: !prev.tracked }))
    } catch (e) {
      // handleAPIError(e)
      alert(e)
    }
  }

  const [exportingDocx, setExportingDocx] = useState(false)
  const [exportingPDF, setExportingPDF] = useState(false)

  const exportDocx = async () => {
    const html = _getSelectionHtml()
    const title = _titleForDoc(doc, Boolean(html))

    setExportingDocx(true)
    const apiCall = html ? api.docxgenForHTML(html) : api.docxgenForDoc(doc.docid, !showReds)
    const fname = `Lex - ${title + (html ? ' (selectie)' : '')}.docx`

    const docx = await apiCall
    const blob = new Blob([docx], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
    window.saveAs(blob, fname);
    setExportingDocx(false)
  }

  const exportPDF = async () => {
    const html = _getSelectionHtml()
    const title = _titleForDoc(doc, Boolean(html))

    setExportingPDF(true)
    const apiCall = html ? api.pdfgenForHTML(html) : api.pdfgenForDoc(doc.docid, !showReds)
    const fname = `Lex - ${title + (html ? ' (selectie)' : '')}.pdf`

    const pdf = await apiCall
    const blob = new Blob([pdf], { type: 'application/pdf' })
    window.saveAs(blob, fname);
    setExportingPDF(false)
  }

  // useEffect(() => {
  //   const _updateHeaderAccordingToScroll = () => {
  //     if (document.documentElement.scrollTop > 60) {
  //       setHeaderMinimize(true)
  //     } else {
  //       setHeaderMinimize(false)
  //     }
  //     console.log('headerMinimise listener')
  //   }
  //   window.addEventListener('scroll', _updateHeaderAccordingToScroll)
  //   return () => window.removeEventListener('scroll', _updateHeaderAccordingToScroll)
  // }, [])

  const subnavRef = useRef()
  const navRef = useRef()

  // const [docTop, setDocTop] = useState(0)
  useEffect(() => {
    if (!presentedDocumentData || !presentedDocumentData.found) return

    (async () => {
      for (let i = 0; i < 50; i++) {
        const elements = document.getElementsByClassName('lex-highlight')
        if (elements.length) {
          _scrollToSearchIndex(searchIndex)
          return
        }
        await new Promise((r) => setInterval(r, 100))
      }
    })()

  }, [presentedDocumentData, docSearchText, searchIndex])

  // useLayoutEffect(() => {
  //   setDocTop(subnavRef.current && navRef.current ? subnavRef.current?.getBoundingClientRect().height + navRef.current?.getBoundingClientRect().height : 0)
  //   console.log('setting doc top')
  // }, [doc])

  const onSearch = () => {
    history.push('/search')
  }

  // keep scroll in session storage

  useEffect(() => {
    if (doc) {

      const wrapper = document.getElementById(`page-wrap-id-${doc.docid}`);
      const listener = () => {
        const wrapper2 = document.getElementById(`page-wrap-id-${doc.docid}`);
        if (wrapper2 && wrapper2.scrollTop > 60) {
          setHeaderMinimize(true)
        } else {
          setHeaderMinimize(false)
        }
        if (wrapper2) {
          // console.log('setting scroll', doc.docid, wrapper2.scrollTop);
          sessionStorage.setItem(`scroll-${doc.docid}`, wrapper2.scrollTop);
        }
      }

      const retrievedScroll = sessionStorage.getItem(`scroll-${doc.docid}`) || 0
      console.log('getting scroll', doc.docid, retrievedScroll)
      if (wrapper && wrapper.scrollTop !== retrievedScroll) {
        wrapper.scrollTo({
          top: retrievedScroll
        })
      }

      if (wrapper) {
        wrapper.addEventListener('scroll', listener)
        return () => wrapper.removeEventListener('scroll', listener);
      }
    }

  }, [doc])

  // print shortcut for electron
  const handlePageKeyPress = useCallback((e) => {
    if (e.key === 'p' && e.ctrlKey === true) {
      printSelection();
    }
  }, [])

  // add event listener for electron print
  useEffect(() => {
    if (isElectron()) {
      document.addEventListener('keydown', handlePageKeyPress);
      return () => {
        document.removeEventListener('keydown', handlePageKeyPress);
      };
    }

  }, [handlePageKeyPress]);

  // console.log(window.scrollY)

  // console.log(docViewerRef.current ? docViewerRef.current.offsetTop : 'nem')

  return (
    <>
      <DocHistorySidePanel doc={doc} />
      <DocRelationsSidePanel doc={doc} />
      <DocTrackingSidePanel doc={doc} toggleTracked={toggleTracked} />
      <div
        id={`page-wrap-id-${doc ? doc.docid : 'undefined'}`}
        className='page-wrap d-flex flex-column align-items-center'
        style={{
          overflowY: 'auto',
          height: mobile ? '100%' : 'calc(100% - 60px)', // desktop top menu
          width: mobile ? '100%' : 'calc(100% - 60px)', // desktop left menu
          marginLeft: mobile ? 0 : '60px' // desktop left menu
        }}
      >
        {
          doc && doc.title
            ? <div id='minimizeable-doc-title' className="container doc-title" title={doc.title} dangerouslySetInnerHTML={{ __html: doc.title }}></div>
            : null
        }
        <div className='container lex-doc-viewer-header' style={{ position: 'sticky', top: 0, zIndex: 2 }}>
          <DocDescription doc={doc} />
          <div className="d-flex justify-content-between align-items-center doc-tools-container">
            {/* <div className="mr-auto">{docSearchBox}</div> */}
            <DocSearchBox
              doc={doc}
              docSearchText={docSearchText}
              searchIndex={searchIndex}
              setSearchIndex={setSearchIndex}
              onSearch={text => {
                setDocSearchText(text)
                history.replace(`/view/${doc.docid}/${encodeURIComponent(text)}`);
              }} />
            <ShowHideReds doc={doc} showReds={showReds} onToggle={() => setShowReds(prev => !prev)} />
            <div className="d-flex flex-column justify-content-between align-items-center" style={{ maxWidth: '50%' }}>
              {
                !mobile
                  ? <div className='d-flex justify-content-between align-items-center'>
                    <DocDOCXDownloadButton doc={doc} loading={exportingDocx} onExport={exportDocx} />
                    <DocPDFDownloadButton doc={doc} loading={exportingPDF} onExport={exportPDF} />
                  </div>
                  : <DocPDFDownloadButton doc={doc} loading={exportingPDF} onExport={exportPDF} />

              }
              {
                isElectron()
                  ? <div className='d-flex justify-content-between align-items-center'>
                    <PrintButton doc={doc} />
                    <PrintButton doc={doc} selectionOnly />
                  </div>
                  : null
              }
            </div>
          </div>
        </div>
        <div id="docviewer-wrap" className={`${showReds ? '' : 'lex-hide-mods'}`} style={{ position: 'relative', marginTop: '0', width: '100%' }}>
          <div
            id={`unique-scrollable-id-${doc ? doc.docid : 'undefined'}`}
            style={{
              height: '100%',
              overflowY: 'hidden',
              paddingTop: '2rem',
              paddingBottom: '1rem',
              position: 'relative'
            }}>

            <div className='container'>
              {loadError && loadError.message === '404'
                ? (
                  <div className="alert alert-danger">
                    <h4 className="alert-heading">Ne pare rau documentul nu a fost gasit</h4>
                    <hr />
                    <p>Va rugam contactati-ne.</p>
                  </div>
                )
                : (
                  <>
                    {
                      doc
                        ? <div
                          style={{
                            fontSize: '10pt', fontFamily: 'Courier New',
                            marginBottom: '1rem'
                          }}
                        >
                          {doc.publisher_name} Nr. {doc.publish_nr} din {dayjs(doc.publish_date).locale('ro').format('DD MMMM YYYY')}
                        </div>
                        : null
                    }
                    {loggedInUser.level === 0 && doc ? <a className="hide4print" href={`${(window._env_ && window._env_.REACT_APP_ADMIN_URL) || window._env_.REACT_APP_ADMIN_URL}/editact/${doc.docid}`} target="_self" rel="noreferrer">Edit</a> : null}
                    <DocumentBody loading={loading} doc={doc} />
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default BetterViewer
